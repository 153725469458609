<template>
  <div id="bsModalNews" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document" @click.stop>
      <div class="modal-content">
        <div class="modal-header">
          <button aria-label="Close" class="close" type="button" @click="close()">
            <span
              aria-hidden="true">&times;
            </span>
          </button>
          <h4 id="bsModalLabel" class="modal-title text-left">{{ title }}</h4>
        </div>
        <div class="modal-body">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <ValidationProvider v-slot="{ errors }" class="form-group" name="title" rules="required" tag="div">
                <label for="title">Title</label>
                <input id="title" v-model="form.title" class="form-control" type="text"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="short description" rules="required"
                                  tag="div">
                <label>Short Description</label>
                <editor v-model="form.short" :model.sync="form.short"></editor>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="description" rules="required" tag="div">
                <label>Description</label>
                <editor v-model="form.post" :model.sync="form.post"></editor>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="picture" rules="required" tag="div">
                <label for="picture">Picture</label>
                <input v-model="form.picture" type="hidden"/>
                <input id="picture" ref="picture" type="file" @change="uploadFile()"/>
                <div v-if="form.picture">
                  <img :src="form.picture" alt="" style="height: 60px;"/>
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="youtube"
                                  rules="isYoutubeURL" tag="div">
                <label for="youtube">Youtube</label>
                <input id="youtube" v-model="form.youtube" class="form-control" type="text"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" class="form-group" name="status" tag="div">
                <label class="flex align-items-center">
                  <input id="frontPage" v-model="form.frontpage" type="checkbox" value="1"/>
                  Is front page post?
                </label>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
              <div class="text-right">
                <button :disabled="isLoading" class="btn btn-primary btn-flat" type="submit">
                  <span v-if="!isLoading">Save</span>
                  <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { logout, saveNotification } from "@/lib/utils";

export default {
  name: "NewsModal",
  data() {
    return {
      title: "",
      news: "",
      form: {
        title: "",
        short: "",
        post: "",
        picture: "",
        youtube: "",
        frontpage: 0,
      },
      isLoading: false,
      confirmHook: () => {
      },
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  methods: {
    confirm: function (id) {
      this.confirmHook(id);
      this.close();
    },
    uploadFile() {
      const files = this.$refs.picture.files;
      if (files.length > 0) {
        const file = files[0];
        if (file.type.startsWith("image/")) {
          const fd = new FormData();
          fd.append("file123", file);
          fd.append("Price", 1);
          // Upload to amazon S3
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.post(`${apiBaseUrl}/MarketAPI?POSBLOB`, fd, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(response => {
            if (response.status === 200) {
              this.form.picture = response.data;
              this.$refs.picture.value = "";
            }
          }).catch(error => {
            console.log(error);
          });
        } else {
          // Clear file
          this.$toast.fire("", "Please provide a valid image file", "error");
        }
      }
    },
    onSubmit: function () {
      this.isLoading = true;
      let payload = this.form;
      payload.frontpage = (payload.frontpage) ? 1 : 0;
      payload = Object.assign(payload, {
        Call: "NEWSPOST",
        new: "",
        category: "cat",
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        output: "json",
      });
      const url = process.env.VUE_APP_API_URL + "/v2REAPI";
      this.$http.post(url, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        this.isLoading = false;
        if (response.status === 200) {
          if (response.data && response.data.status_code === 400) {
            if (response.data.message.toLowerCase() === "no permission") {
              logout();
            } else {
              this.$toast.fire("", response.data.message, "error");
            }
          } else {
            this.$toast.fire("", "News published successfully", "success");
            saveNotification("News published successfully");
            this.confirm(response.data.data);
          }
        }
      }).catch(error => {
        this.isLoading = false;
        console.log(error);
      });
    },
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      this.form = {
        title: "",
        short: "",
        post: "",
        picture: "",
        youtube: "",
        frontpage: 0,
      };
      this.$nextTick(() => this.$refs.observer.reset());
      $("#bsModalNews").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("news-modal", (args) => {
      this.title = args.title;
      this.news = args.data || null;
      this.confirmHook = args.confirm;
      $("#bsModalNews").modal("show");
      if (this.news) {
        this.form = {
          ID: this.news.ID,
          update: 1,
          title: this.news.Title,
          short: this.news.Short,
          post: this.news.Post,
          picture: this.news.Picture,
          youtube: this.news.Youtube,
          frontpage: this.news.FrontPage,
        };
      }
    });
  },
};
</script>

<style lang="scss" scoped></style>
