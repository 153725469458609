var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"bsModalNews","aria-labelledby":"bsModalLabel","data-backdrop":"static","data-keyboard":"false","data-toggle":"modal","role":"dialog","tabindex":"-1"}},[_c('div',{staticClass:"modal-dialog modal-lg",attrs:{"role":"document"},on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('button',{staticClass:"close",attrs:{"aria-label":"Close","type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("× ")])]),_c('h4',{staticClass:"modal-title text-left",attrs:{"id":"bsModalLabel"}},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"modal-body"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"title","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"title"}},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title),expression:"form.title"}],staticClass:"form-control",attrs:{"id":"title","type":"text"},domProps:{"value":(_vm.form.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "title", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"short description","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Short Description")]),_c('editor',{attrs:{"model":_vm.form.short},on:{"update:model":function($event){return _vm.$set(_vm.form, "short", $event)}},model:{value:(_vm.form.short),callback:function ($$v) {_vm.$set(_vm.form, "short", $$v)},expression:"form.short"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"description","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Description")]),_c('editor',{attrs:{"model":_vm.form.post},on:{"update:model":function($event){return _vm.$set(_vm.form, "post", $event)}},model:{value:(_vm.form.post),callback:function ($$v) {_vm.$set(_vm.form, "post", $$v)},expression:"form.post"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"picture","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"picture"}},[_vm._v("Picture")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.picture),expression:"form.picture"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.form.picture)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "picture", $event.target.value)}}}),_c('input',{ref:"picture",attrs:{"id":"picture","type":"file"},on:{"change":function($event){return _vm.uploadFile()}}}),(_vm.form.picture)?_c('div',[_c('img',{staticStyle:{"height":"60px"},attrs:{"src":_vm.form.picture,"alt":""}})]):_vm._e(),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"youtube","rules":"isYoutubeURL","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"youtube"}},[_vm._v("Youtube")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.youtube),expression:"form.youtube"}],staticClass:"form-control",attrs:{"id":"youtube","type":"text"},domProps:{"value":(_vm.form.youtube)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "youtube", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"status","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"flex align-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.frontpage),expression:"form.frontpage"}],attrs:{"id":"frontPage","type":"checkbox","value":"1"},domProps:{"checked":Array.isArray(_vm.form.frontpage)?_vm._i(_vm.form.frontpage,"1")>-1:(_vm.form.frontpage)},on:{"change":function($event){var $$a=_vm.form.frontpage,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="1",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "frontpage", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "frontpage", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "frontpage", $$c)}}}}),_vm._v(" Is front page post? ")]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-primary btn-flat",attrs:{"disabled":_vm.isLoading,"type":"submit"}},[(!_vm.isLoading)?_c('span',[_vm._v("Save")]):_vm._e(),(_vm.isLoading)?_c('span',[_c('i',{staticClass:"fa fa-circle-o-notch fa-spin"})]):_vm._e()])])],1)]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }