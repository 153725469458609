<template>
  <div v-if="!isLoading" class="box box-default">
    <div class="box-header">
      <div class="flex justify-between align-items-center">
        <h3 class="box-title nowrap" style="flex-grow: 1">News</h3>
        <button class="btn btn-primary btn-sm btn-flat ml-8" @click="launchNewsModal()">Add News</button>
      </div>
    </div>
    <div class="box-body no-padding">
      <div v-if="meta.total" class="footer">
        <pagination :meta="meta" @pageChange="updatePage"></pagination>
      </div>
      <div class="table-responsive">
        <table class="table table-condensed table-striped table-borderless">
          <thead>
            <tr>
              <th class="pl-16">ID</th>
              <th>Category</th>
              <th>Title</th>
              <th>Picture</th>
              <th>Youtube</th>
              <th>Vimeo</th>
              <th>Front Page</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-if="meta && meta.total">
              <tr v-for="(record, index) in news" :key="index">
                <td class="pl-16">
                  <a target="_blank" :href="'https://cointopay.com/landing?id=' + record.ID">{{ record.ID }}</a>
                </td>
                <td>{{ record.CategoryName }}</td>
                <td>{{ record.Title }}</td>
                <td>
                  <img v-if="record.Picture" :src="record.Picture" alt="" style="height: 60px;" />
                </td>
                <td>
                  <svg v-if="record.Youtube.trim() !== ''" class="w-24 h-24 pointer" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                    @click="launchVideoModal(record, record.Youtube)">
                    <path
                      d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                      stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                    <path d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"></path>
                  </svg>
                </td>
                <td>
                  <svg v-if="record.Vimeo.trim() !== ''" class="w-24 h-24 pointer" fill="none" stroke="currentColor"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                    @click="launchVideoModal(record, record.Vimeo)">
                    <path
                      d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                      stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                    <path d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"></path>
                  </svg>
                </td>
                <td v-html="format(record.FrontPage)"></td>
                <td class="action-md pr-16">
                  <div class="flex justify-between align-items-center">
                    <a v-tooltip="{ content: 'View Detail', trigger: 'click hover focus' }" class="nav-action"
                      @click="launchNewsDetailModal(record)">
                      <svg class="w-24 h-24" fill="none" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                        <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                        <path
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z">
                        </path>
                      </svg>
                    </a>
                    <a v-tooltip="{ content: 'Edit News', trigger: 'click focus hover' }" class="nav-action"
                      @click="launchNewsModal(record, index)">
                      <svg class="w-24 h-24" fill="none" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                        <path
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z">
                        </path>
                      </svg>
                    </a>
                    <a v-tooltip="{ content: 'Delete News', trigger: 'click focus hover' }" class="nav-action danger"
                      @click="deleteNews(record, index)">
                      <svg class="w-24 h-24" fill="none" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                        <path
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16">
                        </path>
                      </svg>
                    </a>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="8">No record found!</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div v-if="meta.total" class="footer">
        <pagination :meta="meta" @pageChange="updatePage"></pagination>
      </div>
    </div>
    <!-- News Detail Modal -->
    <news-detail-modal></news-detail-modal>
    <!-- News Modal -->
    <news-modal></news-modal>
    <!-- Video Modal -->
    <video-modal></video-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pagination from "@/components/Pagination";
import NewsDetailModal from "@/components/modals/news/NewsDetailModal";
import NewsModal from "@/components/modals/news/NewsModal";
import VideoModal from "@/components/modals/news/VideoModal";
import { isAuthorized, saveNotification } from "@/lib/utils";
import { START_LOADING, STOP_LOADING } from "@/store/keys";

export default {
  name: "News",
  components: {
    VideoModal,
    NewsModal,
    NewsDetailModal,
    Pagination,
  },
  data() {
    return {
      keyword: "",
      news: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      searching: "",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
    ]),
  },
  methods: {
    isAuthorized(p) {
      return isAuthorized(p);
    },
    updatePage(page) {
      this.getPaginatedNews(page);
    },
    getPaginatedNews(page = 1, keyword = "", isLoader = true) {
      if (isLoader) {
        this.$store.commit(START_LOADING);
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListNews`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword,
          limit: this.meta.limit,
        },
      }).then(response => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.news = response.data.data.news;
          this.meta = response.data.data.meta;
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    getNews(id, index = null) {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=News`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          id,
        },
      }).then(response => {
        if (response.status === 200) {
          if (index === null) {
            this.news.splice(0, 0, response.data.data);
            this.$nextTick(() => {
              this.meta = Object.assign({}, this.meta, {
                total: this.meta.total + 1,
              });
            });
          } else {
            this.news.splice(index, 1, response.data.data);
          }
        }
      }).catch(error => {
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    format(val) {
      const formattedStatus = val ? "Yes" : "No";
      const lblClass = val ? "label-success" : "label-info";
      return "<label class=\"label " + lblClass + "\">" + formattedStatus + "</label>";
    },
    launchNewsDetailModal(news) {
      this.$bus.$emit("news-detail-modal", {
        title: news.Title,
        data: news.Post,
      });
    },
    launchNewsModal(news = null, index = null) {
      this.$bus.$emit("news-modal", {
        title: news ? "Update News" : "Add News",
        data: news,
        confirm: (id) => {
          this.getNews(id, index);
        },
      });
    },
    launchVideoModal(record, url) {
      this.$bus.$emit("video-modal", {
        title: record.Title,
        data: url,
      });
    },
    deleteNews(news, index) {
      this.$swal.fire({
        title: "News",
        text: "Are you sure, you want to delete this news?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const payload = {
            Call: "NEWSPOST",
            MerchantID: this.currentUser.ID,
            APIKey: this.currentUser.APIKey,
            ID: news.ID,
            delete: 1,
            output: "json",
          };
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.get(`${apiBaseUrl}/v2REAPI`, {
            params: payload,
          }).then(response => {
            if (response.status === 200) {
              if (response.data && response.data.status_code === 400) {
                this.$toast.fire("", response.data.message, "error");
              } else {
                this.$toast.fire("", "News deleted successfully", "success");
                saveNotification("News deleted successfully");
                this.news.splice(index, 1);
                this.$nextTick(() => {
                  this.meta = Object.assign({}, this.meta, {
                    total: this.meta.total - 1,
                  });
                });
              }
            }
          }).catch(error => {
            console.log(error);
          });
        }
      });
    },
  },
  mounted() {
    this.getPaginatedNews();
  },
  beforeDestroy() {
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style lang="scss" scoped></style>
