<template>
  <div @click="clickOverlay()" aria-labelledby="bsModalLabel" class="modal fade" data-backdrop="static"
       data-keyboard="false" data-toggle="modal"
       id="bsModalVideo" role="dialog" tabindex="-1">
    <div @click.stop class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button @click="close()" aria-label="Close" class="close" type="button"><span
            aria-hidden="true">&times;</span></button>
          <h4 class="modal-title text-left" id="bsModalLabel">{{ title }}</h4>
        </div>
        <div class="modal-body text-center">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" :src="data"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoModal",
  data() {
    return {
      title: "",
      data: "",
    };
  },
  methods: {
    clickOverlay: function () {
      // Hide modal
      this.close();
    },
    close() {
      $("#bsModalVideo").modal("hide");
    },
  },
  mounted() {
    this.$bus.$on("video-modal", (args) => {
      this.title = args.title;
      this.data = args.data || null;
      $("#bsModalVideo").modal("show");
    });
  },
};
</script>

<style scoped></style>
